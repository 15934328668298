import React, {useRef, useEffect} from 'react'
import * as THREE from 'three'
import geckos from '@geckos.io/client'

const ThreeScene = () => {
  const mountRef = useRef(null)
  const playerRef = useRef(null)
  const cameraRef = useRef(null)
  const channel = useRef(null)

  useEffect(() => {
    // Initialize Geckos.io client
    channel.current = geckos({
      url: 'http://tastosis.com',
      port: 3001,
    })

    channel.current.onConnect(error => {
      if (error) {
        console.error('Connection error:', error.message)
        return
      }

      console.log('Connected to Geckos.io server')

      const scene = new THREE.Scene()

      // Create the camera and set its position above the player
      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
      camera.position.set(0, 20, 20) // Position the camera above and slightly behind the player
      camera.lookAt(0, 0, 0)
      cameraRef.current = camera

      // Create the renderer
      const renderer = new THREE.WebGLRenderer()
      renderer.setSize(window.innerWidth, window.innerHeight)
      mountRef.current.appendChild(renderer.domElement)

      // Create a grid helper
      const gridHelper = new THREE.GridHelper(200, 50)
      scene.add(gridHelper)

      // Create a basic player cube with a random color
      const playerGeometry = new THREE.BoxGeometry(1, 1, 1)
      const randomColor = Math.random() * 0xffffff
      const playerMaterial = new THREE.MeshBasicMaterial({color: randomColor})
      const player = new THREE.Mesh(playerGeometry, playerMaterial)
      player.position.y = 0.5
      scene.add(player)
      playerRef.current = player

      // Raycaster setup
      const raycaster = new THREE.Raycaster()
      const mouse = new THREE.Vector2()

      // Handle incoming messages from the server
      channel.current.on('update', ({players}) => {
        const currentPlayerIds = players.map(player => player.id)

        // Add or update players
        players.forEach(({id, x, z, color, timestamp}) => {
          if (id !== channel.current.id) {
            let otherPlayer = scene.getObjectByName(id)
            if (!otherPlayer) {
              const otherPlayerMaterial = new THREE.MeshBasicMaterial({color})
              otherPlayer = new THREE.Mesh(playerGeometry, otherPlayerMaterial)
              otherPlayer.name = id
              otherPlayer.position.y = 0.5
              scene.add(otherPlayer)
            }
            otherPlayer.position.x = x
            otherPlayer.position.z = z

            // Calculate and log the ping (time difference)
            const now = Date.now()
            const ping = now - timestamp
            console.log(`Ping to ${id}: ${ping} ms`)
          }
        })

        // Remove players that have disconnected
        scene.children.forEach(object => {
          if (object.name && !currentPlayerIds.includes(object.name)) {
            scene.remove(object)
          }
        })
      })

      const handleMouseDown = event => {
        // Calculate mouse position in normalized device coordinates
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1

        // Update the raycaster with the camera and mouse position
        raycaster.setFromCamera(mouse, camera)

        // Define the objects to be intersected by the ray
        const intersects = raycaster.intersectObject(gridHelper)

        if (intersects.length > 0) {
          const point = intersects[0].point
          const x = Math.round(point.x)
          const z = Math.round(point.z)
          const timestamp = Date.now() // Capture the current timestamp

          // Move the player to the clicked location and send timestamp
          player.position.set(x, player.position.y, z)
          channel.current.emit('move', {x, z, timestamp})
        }
      }

      window.addEventListener('mousedown', handleMouseDown)

      const animate = function () {
        requestAnimationFrame(animate)

        camera.lookAt(player.position)
        renderer.render(scene, camera)
      }

      animate()

      return () => {
        renderer.dispose()
        mountRef.current.removeChild(renderer.domElement)
        window.removeEventListener('mousedown', handleMouseDown)
      }
    })
  }, [])

  return <div ref={mountRef} style={{width: '100%', height: '100%'}} />
}

export default ThreeScene
