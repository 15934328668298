import React, {Component} from 'react'
import Switch from 'react-input-switch'
import {withCookies, Cookies} from 'react-cookie'
import YouTube from 'react-youtube'

import searchImg from './imgs/search.png'

class New extends Component {
  constructor(props) {
    super(props)

    let {cookies, page} = props

    let cookiesAll = cookies.getAll()
    let cookieArr = Object.values(cookiesAll)

    this.state = {
      darkMode: false,
      cols: [],
      externalVid: 0,
      activeVid: null,
      activeVidArr: [],
      activeVidType: null,
      year: null,
      season: null,
      round: null,
      group: null,
      cookies: cookies || [],
      cookieArr: cookieArr || [],
      bodyHeight: 1000,
      bodyWidth: 1200,
      expandedVid: false,
    }
  }

  componentDidMount() {
    if (this.state.darkMode) {
      document.body.style.backgroundColor = '#212024'
    }
    this.setState({
      bodyWidth: window.innerWidth,
      bodyHeight: window.innerHeight,
    })
    this.getDarkMode()
  }

  getDarkMode = () => {
    /*
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode
      console.log('darkmode browser')
    } else {
      console.log('lightmode browser')
    }
    */

    if (this.state.cookieArr.includes('darkmode-true')) {
      this.toggleDarkMode(true)
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode
      this.toggleDarkMode(true)
    } else {
    }
  }

  toggleDarkMode = (auto = false) => {
    if (this.state.darkMode) {
      document.body.style.backgroundColor = '#fbf7f5'
    } else {
      document.body.style.backgroundColor = '#212024'
    }
    this.setState({darkMode: !this.state.darkMode})
    if (!auto) {
      const {cookies} = this.props
      let darkmodeString = `darkmode-${!this.state.darkMode}`
      cookies.set('darkmode', darkmodeString, {path: '/'})
    }
  }

  expandToggle = () => {
    this.setState({expandedVid: !this.state.expandedVid})
  }

  dim = () => {
    let ytWidth = this.state.bodyWidth - 300
    let ytHeight = (ytWidth / 16) * 9

    if (ytHeight > this.state.bodyHeight) {
      ytHeight = this.state.bodyHeight - 200
      ytWidth = (ytHeight / 9) * 16
    }

    return {
      width: this.state.expandedVid ? ytWidth : 960,
      height: this.state.expandedVid ? ytHeight : 480,
    }
  }

  vid = () => {
    const id = 'krJYutGZ5Sk'
    const videoUrl = `https://tastosis.nyc3.cdn.digitaloceanspaces.com/${id}.mp4`

    return (
      <div className="video-player">
        <video controls autoPlay src={videoUrl}>
          Your browser does not support the video tag.
        </video>
      </div>
    )
  }

  setVid = id => {
    this.setState({id})
    window.scrollTo(0, 0)
  }

  gameVid = (id, year, season, round, group, type = 'twitch') => {
    let {cookieArr} = this.state
    let colorShow = cookieArr.includes(`${id}-${year},${season},${round},${group}`)
    let setLinkCss = 'setLink'
    if (colorShow) setLinkCss = 'setLinkSeen'
    if (this.state.darkMode) setLinkCss = `${setLinkCss}Dark`
    return (
      <button className={setLinkCss} onClick={() => this.setVid(id)}>
        {group}
      </button>
    )
  }

  render() {
    const {innerWidth: width, innerHeight: height} = window
    let {id} = this.state

    console.log('this.state', this.state)

    let {round, group, season, year, darkMode, activeVid, activeVidArr, activeVidType, expandedVid} = this.state
    let groupName = `Match ${group}`
    if (round == '32' || round == '16') {
      groupName = `Group ${group}`
    }
    if (round == '2') {
      groupName = 'Finals'
    }
    let roundName = `- ro${round}`
    if (round == '2') {
      roundName = ''
    }

    let setLinkCss = 'setLink'
    let emptyRoundCss = 'emptyRound'
    let vidDescriptionStyle = 'vidDescription'
    if (darkMode) {
      setLinkCss = 'setLinkDark'
      emptyRoundCss = 'emptyRoundDark'
      vidDescriptionStyle = 'vidDescriptionDark'
    }

    return (
      <div className={'page'}>
        <div className={'top'}>
          <div className={'topMid'}>
            tastosis
            <div>
              <Switch
                styles={{
                  marginBottom: 10,
                  trackChecked: {
                    backgroundColor: '#555',
                  },
                  buttonChecked: {
                    backgroundColor: '#212024',
                  },
                  height: 16,
                  width: 26,
                }}
                value={this.state.darkMode ? 1 : 0}
                onChange={() => this.toggleDarkMode()}
              />
            </div>
          </div>
        </div>

        {id && (
          <div className={'vidBox'}>
            <div style={{marginTop: 10}}>{this.vid(id)}</div>
            <div
              onClick={() => this.expandToggle()}
              className={'fas fa-expand-arrows-alt expandButton'}
              style={{
                color: expandedVid ? '#555' : '#ccc',
                alignSelf: 'flex-end',
                fontSize: 21,
                marginTop: 7,
                marginRight: 7,
              }}
            />
          </div>
        )}

        <div className={'main'}>
          <div className={'gslRow'}>
            <div className={'yearRow'}>
              <div className={'yearTitle'}>2010</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2011</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2012</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2013</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>
              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s1</div>
                <div className={'ro32'}>
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '32', 'A')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '32', 'B')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '32', 'C')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '32', 'D')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '32', 'E')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '32', 'F')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '32', 'G')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '32', 'H')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '16', 'A')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '16', 'C')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '16', 'B')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '16', 'D')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '8', '1')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '8', '2')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '4', '1')}
                  {this.gameVid('krJYutGZ5Sk', '2013', '3', '4', '2')}
                </div>
                <div className={'ro2'}>{this.gameVid('krJYutGZ5Sk', '2013', '3', '2', 'F')}</div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2014</div>

              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s1</div>
                <div className={`ro32 ${emptyRoundCss}`} />
                <div className={'ro16'}>
                  {this.gameVid(30147066, '2014', '1', '16', 'A', 'twitch')}
                  {this.gameVid(30146842, '2014', '1', '16', 'B', 'twitch')}
                  {this.gameVid(30146719, '2014', '1', '16', 'D', 'twitch')}
                  {this.gameVid(30146723, '2014', '1', '16', 'C', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid(30146598, '2014', '1', '8', '1')}
                  {this.gameVid(30146709, '2014', '1', '8', '2')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid(30146628, '2014', '1', '4', '1')}
                  {this.gameVid(30146508, '2014', '1', '4', '2')}
                </div>
                <div className={'ro2'}>{this.gameVid(30146566, '2014', '1', '2', 'F')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s2</div>
                <div className={'ro32'}>
                  {this.gameVid('30146124', '2014', '2', '32', 'A', 'twitch')}
                  {this.gameVid('30146044', '2014', '2', '32', 'B', 'twitch')}
                  {this.gameVid('30145938', '2014', '2', '32', 'C', 'twitch')}
                  {this.gameVid('30145838', '2014', '2', '32', 'D', 'twitch')}
                  {this.gameVid('30145641', '2014', '2', '32', 'E', 'twitch')}
                  {this.gameVid('30145753', '2014', '2', '32', 'F', 'twitch')}
                  {this.gameVid('30145809', '2014', '2', '32', 'G', 'twitch')}
                  {this.gameVid('30145555', '2014', '2', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('30145369', '2014', '2', '16', 'A', 'twitch')}
                  {this.gameVid('30145437', '2014', '2', '16', 'C', 'twitch')}
                  {this.gameVid('30145345', '2014', '2', '16', 'B', 'twitch')}
                  {this.gameVid('30145169', '2014', '2', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('30145230', '2014', '2', '8', '1', 'twitch')}
                  {this.gameVid('30145079', '2014', '2', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('30145138', '2014', '2', '4', '1', 'twitch')}
                  {this.gameVid('30144873', '2014', '2', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('30144907', '2014', '2', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s3</div>
                <div className={'ro32'}>
                  {this.gameVid('30144080', '2014', '3', '32', 'A', 'twitch')}
                  {this.gameVid('30143958', '2014', '3', '32', 'B', 'twitch')}
                  {this.gameVid('30143956', '2014', '3', '32', 'C', 'twitch')}
                  {this.gameVid('30143807', '2014', '3', '32', 'D', 'twitch')}
                  {this.gameVid('30143626', '2014', '3', '32', 'E', 'twitch')}
                  {this.gameVid('30143716', '2014', '3', '32', 'F', 'twitch')}
                  {this.gameVid('30143414', '2014', '3', '32', 'G', 'twitch')}
                  {this.gameVid('30143312', '2014', '3', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('30143201', '2014', '3', '16', 'A', 'twitch')}
                  {this.gameVid('30142998', '2014', '3', '16', 'C', 'twitch')}
                  {this.gameVid('30142819', '2014', '3', '16', 'B', 'twitch')}
                  {this.gameVid('30142781', '2014', '3', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('30142702', '2014', '3', '8', '1', 'twitch')}
                  {this.gameVid('30142599', '2014', '3', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('30142545', '2014', '3', '4', '1', 'twitch')}
                  {this.gameVid('30142467', '2014', '3', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('30142554', '2014', '3', '2', 'F', 'twitch')}</div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2015</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32 extra32Title'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s1</div>
                <div className={'ro32'}>
                  {this.gameVid('30141527', '2015', '1', '32', 'A', 'twitch')}
                  {this.gameVid('yt:30141471,30141208', '2015', '1', '32', 'B', 'twitchMulti')}
                  {this.gameVid('30141604', '2015', '1', '32', 'C', 'twitch')}
                  {this.gameVid('38103194', '2015', '1', '32', 'F', 'twitch')}
                  {this.gameVid('30141010', '2015', '1', '32', 'E', 'twitch')}
                  {this.gameVid('30141054', '2015', '1', '32', 'H', 'twitch')}
                  {this.gameVid('30140959', '2015', '1', '32', 'G', 'twitch')}
                  {this.gameVid('30141006', '2015', '1', '32', 'D', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('38103168', '2015', '1', '16', 'A', 'twitch')}
                  {this.gameVid('30140324', '2015', '1', '16', 'B', 'twitch')}
                  {this.gameVid('30140462', '2015', '1', '16', 'C', 'twitch')}
                  {this.gameVid('yt:38103159,30140129', '2015', '1', '16', 'D', 'twitchMulti')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('30140286', '2015', '1', '8', '1', 'twitch')}
                  {this.gameVid('30140141', '2015', '1', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('30139753', '2015', '1', '4', '1', 'twitch')}
                  {this.gameVid('30140044', '2015', '1', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('30139971', '2015', '1', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s2</div>
                <div className={'ro32'}>
                  {this.gameVid('30140111', '2015', '2', '32', 'A', 'twitch')}
                  {this.gameVid('30140011', '2015', '2', '32', 'B', 'twitch')}
                  {this.gameVid('4563798', '2015', '2', '32', 'C', 'twitch')}
                  {this.gameVid('30139782', '2015', '2', '32', 'D', 'twitch')}
                  {this.gameVid('30139924', '2015', '2', '32', 'E', 'twitch')}
                  {this.gameVid('yt:30139573,30139734', '2015', '2', '32', 'F', 'twitchMulti')}
                  {this.gameVid('30139730', '2015', '2', '32', 'G', 'twitch')}
                  {this.gameVid('30139840', '2015', '2', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('30139569', '2015', '2', '16', 'A', 'twitch')}
                  {this.gameVid('30139717', '2015', '2', '16', 'D', 'twitch')}
                  {this.gameVid('30139596', '2015', '2', '16', 'C', 'twitch')}
                  {this.gameVid('yt:30139575,30139592,30139413', '2015', '2', '32', 'B', 'twitchMulti')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('30139676', '2015', '2', '8', '1', 'twitch')}
                  {this.gameVid('30139504', '2015', '2', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('30139389', '2015', '2', '4', '1', 'twitch')}
                  {this.gameVid('30139359', '2015', '2', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('30139316', '2015', '2', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s3</div>
                <div className={'ro32'}>
                  {this.gameVid('30138869', '2015', '3', '32', 'A', 'twitch')}
                  {this.gameVid('38103167', '2015', '3', '32', 'B', 'twitch')}
                  {this.gameVid('30138926', '2015', '3', '32', 'C', 'twitch')}
                  {this.gameVid('30138968', '2015', '3', '32', 'D', 'twitch')}
                  {this.gameVid('30138917', '2015', '3', '32', 'E', 'twitch')}
                  {this.gameVid('30138868', '2015', '3', '32', 'F', 'twitch')}
                  {this.gameVid('yt:30138791,30138842,38103288', '2015', '3', '32', 'G', 'twitchMulti')}
                  {this.gameVid('30138934', '2015', '3', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('30138897', '2015', '3', '16', 'A', 'twitch')}
                  {this.gameVid('30138976', '2015', '3', '16', 'B', 'twitch')}
                  {this.gameVid('30138923', '2015', '3', '16', 'C', 'twitch')}
                  {this.gameVid('30138890', '2015', '3', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('30138716', '2015', '3', '8', '1', 'twitch')}
                  {this.gameVid('30138720', '2015', '3', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('30138683', '2015', '3', '4', '1', 'twitch')}
                  {this.gameVid('30138678', '2015', '3', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('30138890', '2015', '3', '2', 'F', 'twitch')}</div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2016</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s1</div>
                <div className={'ro32'}>
                  {this.gameVid('46504965', '2016', '1', '32', 'A', 'twitch')}
                  {this.gameVid('47544509', '2016', '1', '32', 'B', 'twitch')}
                  {this.gameVid('49825632', '2016', '1', '32', 'C', 'twitch')}
                  {this.gameVid('50807988', '2016', '1', '32', 'D', 'twitch')}
                  {this.gameVid('yt:51868875,51887477', '2016', '1', '32', 'E', 'twitchMulti')}
                  {this.gameVid('52252240', '2016', '1', '32', 'F', 'twitch')}
                  {this.gameVid('53320721', '2016', '1', '32', 'G', 'twitch')}
                  {this.gameVid('53715710', '2016', '1', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('57936043', '2016', '1', '16', 'A', 'twitch')}
                  {this.gameVid('58141981', '2016', '1', '16', 'B', 'twitch')}
                  {this.gameVid('58917017', '2016', '1', '16', 'C', 'twitch')}
                  {this.gameVid('59283457', '2016', '1', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('60303756', '2016', '1', '8', '1', 'twitch')}
                  {this.gameVid('60686317', '2016', '1', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('61724267', '2016', '1', '4', '1', 'twitch')}
                  {this.gameVid('62091378', '2016', '1', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('63880038', '2016', '1', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s2</div>
                <div className={'ro32'}>
                  {this.gameVid('76518429', '2016', '2', '32', 'A', 'twitch')}
                  {this.gameVid('76889536', '2016', '2', '32', 'B', 'twitch')}
                  {this.gameVid('77800311', '2016', '2', '32', 'C', 'twitch')}
                  {this.gameVid('78156016', '2016', '2', '32', 'D', 'twitch')}
                  {this.gameVid('79028235', '2016', '2', '32', 'E', 'twitch')}
                  {this.gameVid('79373355', '2016', '2', '32', 'F', 'twitch')}
                  {this.gameVid('80241969', '2016', '2', '32', 'G', 'twitch')}
                  {this.gameVid('80598323', '2016', '2', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('82775577', '2016', '2', '16', 'A', 'twitch')}
                  {this.gameVid('83147556', '2016', '2', '16', 'B', 'twitch')}
                  {this.gameVid('84091923', '2016', '2', '16', 'C', 'twitch')}
                  {this.gameVid('84455104', '2016', '2', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('85403191', '2016', '2', '8', '1', 'twitch')}
                  {this.gameVid('85764176', '2016', '2', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('86740335', '2016', '2', '4', '1', 'twitch')}
                  {this.gameVid('87110806', '2016', '2', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('88547297', '2016', '2', '2', 'F', 'twitch')}</div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2017</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s1</div>
                <div className={'ro32'}>
                  {this.gameVid('112006949', '2017', '1', '32', 'A', 'twitch')}
                  {this.gameVid('112743110', '2017', '1', '32', 'B', 'twitch')}
                  {this.gameVid('113966528', '2017', '1', '32', 'C', 'twitch')}
                  {this.gameVid('114595757', '2017', '1', '32', 'D', 'twitch')}
                  {this.gameVid('115559719', '2017', '1', '32', 'E', 'twitch')}
                  {this.gameVid('116195027', '2017', '1', '32', 'H', 'twitch')}
                  {this.gameVid('117197418', '2017', '1', '32', 'G', 'twitch')}
                  {this.gameVid('117423750', '2017', '1', '32', 'F', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('120556078', '2017', '1', '16', 'A', 'twitch')}
                  {this.gameVid('121235339', '2017', '1', '16', 'B', 'twitch')}
                  {this.gameVid('122231753', '2017', '1', '16', 'C', 'twitch')}
                  {this.gameVid('122879265', '2017', '1', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('123892734', '2017', '1', '8', '1', 'twitch')}
                  {this.gameVid('124559293', '2017', '1', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('3HAy3VumC4o', '2017', '1', '4', '1', 'youtube')}
                  {this.gameVid('kDaXcaURBZA', '2017', '1', '4', '2', 'youtube')}
                </div>
                <div className={'ro2'}>{this.gameVid('yt:cQFe898vjG0,hxdYtNewI4o,XgJPKWaqvhI,_Yl5ZVoGp8w,0CCIFtRPIJQ,Wg_X-0Vc8v8,0', '2017', '1', '2', 'F', 'youtubeMulti')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s2</div>
                <div className={'ro32'}>
                  {this.gameVid('136617673', '2017', '2', '32', 'A', 'twitch')}
                  {this.gameVid('137241203', '2017', '2', '32', 'B', 'twitch')}
                  {this.gameVid('138187370', '2017', '2', '32', 'C', 'twitch')}
                  {this.gameVid('138799521', '2017', '2', '32', 'D', 'twitch')}
                  {this.gameVid('139785297', '2017', '2', '32', 'E', 'twitch')}
                  {this.gameVid('140462353', '2017', '2', '32', 'F', 'twitch')}
                  {this.gameVid('141494284', '2017', '2', '32', 'G', 'twitch')}
                  {this.gameVid('142629656', '2017', '2', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('146815789', '2017', '2', '16', 'A', 'twitch')}
                  {this.gameVid('147466582', '2017', '2', '16', 'B', 'twitch')}
                  {this.gameVid('148442138', '2017', '2', '16', 'C', 'twitch')}
                  {this.gameVid('149113534', '2017', '2', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('150124496', '2017', '2', '8', '1', 'twitch')}
                  {this.gameVid('150354056', '2017', '2', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('151750128', '2017', '2', '4', '1', 'twitch')}
                  {this.gameVid('152407842', '2017', '2', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('154040659', '2017', '2', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s3</div>
                <div className={'ro32'}>
                  {this.gameVid('156745763', '2017', '3', '32', 'A', 'twitch')}
                  {this.gameVid('157450453', '2017', '3', '32', 'B', 'twitch')}
                  {this.gameVid('158522475', '2017', '3', '32', 'C', 'twitch')}
                  {this.gameVid('159245498', '2017', '3', '32', 'D', 'twitch')}
                  {this.gameVid('160026644', '2017', '3', '32', 'E', 'twitch')}
                  {this.gameVid('160304024', '2017', '3', '32', 'F', 'twitch')}
                  {this.gameVid('160566064', '2017', '3', '32', 'G', 'twitch')}
                  {this.gameVid('161031694', '2017', '3', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('167383603', '2017', '3', '16', 'A', 'twitch')}
                  {this.gameVid('168103663', '2017', '3', '16', 'B', 'twitch')}
                  {this.gameVid('169143214', '2017', '3', '16', 'C', 'twitch')}
                  {this.gameVid('169859424', '2017', '3', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('170927262', '2017', '3', '8', '1', 'twitch')}
                  {this.gameVid('171604534', '2017', '3', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('172677439', '2017', '3', '4', '1', 'twitch')}
                  {this.gameVid('173399376', '2017', '3', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('175136801', '2017', '3', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>st1</div>
                <div className={'roSt12017'}>
                  {this.gameVid('133973678', '2017', 'st1', '1', '1', 'twitch')}
                  {this.gameVid('134200130', '2017', 'st1', '2', '2', 'twitch')}
                  {this.gameVid('134433336', '2017', 'st1', '3', '3', 'twitch')}
                </div>
                <div className={'seasonTitle eventTitle'}>GvW</div>
                <div className={'ro16'}>
                  {this.gameVid('164081284', '2017', 'GvW', '1', '1', 'twitch')}
                  {this.gameVid('164331651', '2017', 'GvW', '2', '2', 'twitch')}
                  {this.gameVid('164564018', '2017', 'GvW', '3', '3', 'twitch')}
                  {this.gameVid('164815583', '2017', 'GvW', 'F', 'F', 'twitch')}
                </div>
                <div className={'seasonTitle eventTitle'}>st2</div>
                <div className={'ro16'}>
                  {this.gameVid('178068896', '2017', 'st2', '1', '1', 'twitch')}
                  {this.gameVid('178309498', '2017', 'st2', '2', '2', 'twitch')}
                  {this.gameVid('178572145', '2017', 'st2', '3', '3', 'twitch')}
                  {this.gameVid('178851634', '2017', 'st2', '4', '4', 'twitch')}
                </div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2018</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s1</div>
                <div className={'ro32'}>
                  {this.gameVid('215853239', '2018', '1', '32', 'A', 'twitch')}
                  {this.gameVid('217175580', '2018', '1', '32', 'B', 'twitch')}
                  {this.gameVid('218044253', '2018', '1', '32', 'C', 'twitch')}
                  {this.gameVid('219438039', '2018', '1', '32', 'D', 'twitch')}
                  {this.gameVid('220382739', '2018', '1', '32', 'E', 'twitch')}
                  {this.gameVid('221815341', '2018', '1', '32', 'F', 'twitch')}
                  {this.gameVid('222779462', '2018', '1', '32', 'G', 'twitch')}
                  {this.gameVid('224212204', '2018', '1', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('227496783', '2018', '1', '16', 'A', 'twitch')}
                  {this.gameVid('228930208', '2018', '1', '16', 'B', 'twitch')}
                  {this.gameVid('231260205', '2018', '1', '16', 'C', 'twitch')}
                  {this.gameVid('231605405', '2018', '1', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('236809789', '2018', '1', '8', '1', 'twitch')}
                  {this.gameVid('237111286', '2018', '1', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('241142898', '2018', '1', '4', '1', 'twitch')}
                  {this.gameVid('242193609', '2018', '1', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('244957590', '2018', '1', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s2</div>
                <div className={'ro32'}>
                  {this.gameVid('251682648', '2018', '2', '32', 'A', 'twitch')}
                  {this.gameVid('252721240', '2018', '2', '32', 'B', 'twitch')}
                  {this.gameVid('254275589', '2018', '2', '32', 'C', 'twitch')}
                  {this.gameVid('255284705', '2018', '2', '32', 'D', 'twitch')}
                  {this.gameVid('256847144', '2018', '2', '32', 'E', 'twitch')}
                  {this.gameVid('257862930', '2018', '2', '32', 'F', 'twitch')}
                  {this.gameVid('259387234', '2018', '2', '32', 'G', 'twitch')}
                  {this.gameVid('260430127', '2018', '2', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('264548072', '2018', '2', '16', 'A', 'twitch')}
                  {this.gameVid('265626566', '2018', '2', '16', 'B', 'twitch')}
                  {this.gameVid('267274806', '2018', '2', '16', 'C', 'twitch')}
                  {this.gameVid('268373345', '2018', '2', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('269989432', '2018', '2', '8', '1', 'twitch')}
                  {this.gameVid('271095631', '2018', '2', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('272725460', '2018', '2', '4', '1', 'twitch')}
                  {this.gameVid('273800422', '2018', '2', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('276549630', '2018', '2', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s3</div>
                <div className={'ro32'}>
                  {this.gameVid('280725575', '2018', '3', '32', 'A', 'twitch')}
                  {this.gameVid('yt:281737201,281743116,281781862', '2018', '3', '32', 'B', 'twitchMulti')}
                  {this.gameVid('283310922', '2018', '3', '32', 'C', 'twitch')}
                  {this.gameVid('284436475', '2018', '3', '32', 'D', 'twitch')}
                  {this.gameVid('285698142', '2018', '3', '32', 'E', 'twitch')}
                  {this.gameVid('286114614', '2018', '3', '32', 'F', 'twitch')}
                  {this.gameVid('287272458', '2018', '3', '32', 'G', 'twitch')}
                  {this.gameVid('287681835', '2018', '3', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('297574291', '2018', '3', '16', 'A', 'twitch')}
                  {this.gameVid('298725738', '2018', '3', '16', 'B', 'twitch')}
                  {this.gameVid('300417617', '2018', '3', '16', 'C', 'twitch')}
                  {this.gameVid('301550889', '2018', '3', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('303238621', '2018', '3', '8', '1', 'twitch')}
                  {this.gameVid('304828847', '2018', '3', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('306142088', '2018', '3', '4', '1', 'twitch')}
                  {this.gameVid('307316862', '2018', '3', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('310324786', '2018', '3', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>st1</div>
                <div className={'ro16'}>
                  {this.gameVid('246840313', '2018', 'st1', '1', '1', 'twitch')}
                  {this.gameVid('247202754', '2018', 'st1', '2', '2', 'twitch')}
                  {this.gameVid('247588499', '2018', 'st1', '3', '3', 'twitch')}
                  {this.gameVid('247988980', '2018', 'st1', '4', '4', 'twitch')}
                </div>
                <div className={'seasonTitle eventTitle'}>GvW</div>
                <div className={'ro16'}>
                  {this.gameVid('292115061', '2018', 'GvW', '1', '1', 'twitch')}
                  {this.gameVid('292504330', '2018', 'GvW', '2', '2', 'twitch')}
                  {this.gameVid('292916873', '2018', 'GvW', '3', '3', 'twitch')}
                  {this.gameVid('293320065', '2018', 'GvW', 'F', 'F', 'twitch')}
                </div>
                <div className={'seasonTitle eventTitle'}>st2</div>
                <div className={'ro16'}>
                  {this.gameVid('315292388', '2018', 'st2', '1', '1', 'twitch')}
                  {this.gameVid('315708539', '2018', 'st2', '2', '2', 'twitch')}
                  {this.gameVid('316137020', '2018', 'st2', '3', '3', 'twitch')}
                  {this.gameVid('316589895', '2018', 'st2', '4', '4', 'twitch')}
                </div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2019</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s1</div>
                <div className={'ro32'}>
                  {this.gameVid('373762614', '2019', '1', '32', 'A', 'twitch')}
                  {this.gameVid('376514426', '2019', '1', '32', 'B', 'twitch')}
                  {this.gameVid('377058697', '2019', '1', '32', 'C', 'twitch')}
                  {this.gameVid('377536376', '2019', '1', '32', 'D', 'twitch')}
                  {this.gameVid('379916717', '2019', '1', '32', 'E', 'twitch')}
                  {this.gameVid('380448070', '2019', '1', '32', 'F', 'twitch')}
                  {this.gameVid('381382459', '2019', '1', '32', 'G', 'twitch')}
                  {this.gameVid('383707343', '2019', '1', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('392173394', '2019', '1', '16', 'A', 'twitch')}
                  {this.gameVid('392605384', '2019', '1', '16', 'D', 'twitch')}
                  {this.gameVid('398322231', '2019', '1', '16', 'C', 'twitch')}
                  {this.gameVid('399742428', '2019', '1', '16', 'B', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('401910445', '2019', '1', '8', '1', 'twitch')}
                  {this.gameVid('403338946', '2019', '1', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('405440725', '2019', '1', '4', '1', 'twitch')}
                  {this.gameVid('406852587', '2019', '1', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('410862108', '2019', '1', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s2</div>
                <div className={'ro32'}>
                  {this.gameVid('417946817', '2019', '2', '32', 'A', 'twitch')}
                  {this.gameVid('418361655', '2019', '2', '32', 'B', 'twitch')}
                  {this.gameVid('418809272', '2019', '2', '32', 'C', 'twitch')}
                  {this.gameVid('420086886', '2019', '2', '32', 'D', 'twitch')}
                  {this.gameVid('421137858', '2019', '2', '32', 'E', 'twitch')}
                  {this.gameVid('421557240', '2019', '2', '32', 'F', 'twitch')}
                  {this.gameVid('421985182', '2019', '2', '32', 'G', 'twitch')}
                  {this.gameVid('423249656', '2019', '2', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('428209068', '2019', '2', '16', 'A', 'twitch')}
                  {this.gameVid('429504581', '2019', '2', '16', 'B', 'twitch')}
                  {this.gameVid('431477566', '2019', '2', '16', 'C', 'twitch')}
                  {this.gameVid('432835185', '2019', '2', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('434735555', '2019', '2', '8', '1', 'twitch')}
                  {this.gameVid('436025794', '2019', '2', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('437932462', '2019', '2', '4', '1', 'twitch')}
                  {this.gameVid('439264043', '2019', '2', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('442556912', '2019', '2', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s3</div>
                <div className={'ro32'}>
                  {this.gameVid('447628576', '2019', '3', '32', 'A', 'twitch')}
                  {this.gameVid('448088548', '2019', '3', '32', 'B', 'twitch')}
                  {this.gameVid('448875639', '2019', '3', '32', 'C', 'twitch')}
                  {this.gameVid('450748329', '2019', '3', '32', 'D', 'twitch')}
                  {this.gameVid('451221911', '2019', '3', '32', 'E', 'twitch')}
                  {this.gameVid('452050302', '2019', '3', '32', 'F', 'twitch')}
                  {this.gameVid('453951915', '2019', '3', '32', 'G', 'twitch')}
                  {this.gameVid('455284626', '2019', '3', '32', 'H', 'twitch')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('473441718', '2019', '3', '16', 'A', 'twitch')}
                  {this.gameVid('474743696', '2019', '3', '16', 'B', 'twitch')}
                  {this.gameVid('476650716', '2019', '3', '16', 'C', 'twitch')}
                  {this.gameVid('477957475', '2019', '3', '16', 'D', 'twitch')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('479461678', '2019', '3', '8', '1', 'twitch')}
                  {this.gameVid('479896058', '2019', '3', '8', '2', 'twitch')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('483036148', '2019', '3', '4', '1', 'twitch')}
                  {this.gameVid('484296941', '2019', '3', '4', '2', 'twitch')}
                </div>
                <div className={'ro2'}>{this.gameVid('487397262', '2019', '3', '2', 'F', 'twitch')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>st1</div>
                <div className={'ro16'}>
                  {this.gameVid('412780694', '2019', 'st1', '1', '1', 'twitch')}
                  {this.gameVid('413259346', '2019', 'st1', '2', '2', 'twitch')}
                  {this.gameVid('413768737', '2019', 'st1', '3', '3', 'twitch')}
                  {this.gameVid('414259913', '2019', 'st1', '4', '4', 'twitch')}
                </div>
                <div className={'seasonTitle eventTitle'}>GvW</div>
                <div className={'roGvW'}>
                  {this.gameVid('467444087', '2019', 'GvW', '1', '1', 'twitch')}
                  {this.gameVid('467895641', '2019', 'GvW', '2', '2', 'twitch')}
                  {this.gameVid('468350654', '2019', 'GvW', '3', '3', 'twitch')}
                  {this.gameVid('468351357', '2019', 'GvW', '4', '4', 'twitch')}
                  {this.gameVid('468813965', '2019', 'GvW', '5', '5', 'twitch')}
                </div>
                <div className={'seasonTitle eventTitle'}>st2</div>
                <div className={'ro16'}>
                  {this.gameVid('489581820', '2019', 'st2', '1', '1', 'twitch')}
                  {this.gameVid('489998936', '2019', 'st2', '2', '2', 'twitch')}
                  {this.gameVid('490447762', '2019', 'st2', '3', '3', 'twitch')}
                  {this.gameVid('490922070', '2019', 'st2', '4', '4', 'twitch')}
                </div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2020</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s1</div>
                <div className={'ro32'}>
                  {this.gameVid('yt:TQCPWv5wQnI,WXYhnv9pvA8,GGfu24y_czo,SrDB5I62p8c,SA4esnp4xPE', '2020', '1', '32', 'A', 'youtubeMulti')}
                  {this.gameVid('yt:mQrJZwenLks,e-Pg_rc-ih4,jiAw-9ZUgAE,vQjyvy1SHrg,0J5mSV2kMmg', '2020', '1', '32', 'B', 'youtubeMulti')}
                  {this.gameVid('yt:uiikpLukf7k,KOU2g7r5CA0,uHDqe3gJPE8,KWuwZQUxkOQ,WJV9cp56vJQ', '2020', '1', '32', 'C', 'youtubeMulti')}
                  {this.gameVid('yt:Yzh4YSPUo2o,oASO9oig5q0,Jw_ZJXvDsZE,O-cEfN7yC2k,WOnPQ__jkLE', '2020', '1', '32', 'D', 'youtubeMulti')}
                  {this.gameVid('yt:Q5MsxFRoEi8,aQ2heHGFPEU,uD2Z8XrnHxM,1MW1rivy2GM,M25Vc6gB2cY', '2020', '1', '32', 'E', 'youtubeMulti')}
                  {this.gameVid('yt:imIgKWqE3Uk,4g9Z18tJAAw,aNZ64m6XwwE,5k-VsVkcQKA,rNTnUgkFrnA', '2020', '1', '32', 'F', 'youtubeMulti')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('yt:GAi1fXdhwKY,GzIlIKrzpr0,X7mSq2dj62U,qi-POut8zUo,Tri7-hDx3Aw', '2020', '1', '16', 'A', 'youtubeMulti')}
                  {this.gameVid('yt:FeUBx14GVNw,Mwdqs4rDtUU,EUvhQqwiKwY,DJyXSk1mBJY,ZzAR1JRZs3c', '2020', '1', '16', 'B', 'youtubeMulti')}
                  {this.gameVid('yt:GoS1qpVOKWQ,6xN-e4tSONo,JA1g0-G7-ss,k7K6b97q8Bk,TLyYW8sTEyA', '2020', '1', '16', 'C', 'youtubeMulti')}
                  {this.gameVid('yt:J0UDg2UK4tk,d596N0-XyFk,BVGZ__-WXnc,Cey98O-2iYw,gHRQ7741SpU', '2020', '1', '16', 'D', 'youtubeMulti')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('yt:xieyHPqkXtE,GOqxkPW9iFA', '2020', '1', '8', '1', 'youtubeMulti')}
                  {this.gameVid('yt:ZKcocqR1oOI,lBztpncrgz8', '2020', '1', '8', '2', 'youtubeMulti')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('U5kUSD4twoY', '2020', '1', '4', '1', 'youtube')}
                  {this.gameVid('_LaA-n_mC7g', '2020', '1', '4', '2', 'youtube')}
                </div>
                <div className={'ro2'}>{this.gameVid('HsqohXWA5eg', '2020', '1', '2', 'F', 'youtube')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s2</div>
                <div className={'ro32'}>
                  {this.gameVid('yt:dyWVqeeevH4,OA8_PAETKP0,u5H1fxiMXrk,2zn1JkU1jzk,oAIe_0EMMwM', '2020', '2', '32', 'A', 'youtubeMulti')}
                  {this.gameVid('yt:MONeqFDYdoY,VQ345LM6HFg,xWBcwRHQ_mo,kOTm5aDmhTc,B8RKgJHoyQY', '2020', '2', '32', 'B', 'youtubeMulti')}
                  {this.gameVid('yt:h5KvQ6ahvkA,v18VK5Ksst0,Ya8y1g92-rk,9y_yfu5mf3U,xlf65u80-MU', '2020', '2', '32', 'C', 'youtubeMulti')}
                  {this.gameVid('yt:jTWbzSUDos4,PcLfe4-VQF8,0GWocZNQwMk,4aKXWWfaWv0,AxftyhPXt3w', '2020', '2', '32', 'D', 'youtubeMulti')}
                  {this.gameVid('yt:sShf_tAQIKM,5hP18HRnNnY,ExLGl6atdRE,IKoFl6z62vk,hFwnljcF2yQ', '2020', '2', '32', 'E', 'youtubeMulti')}
                  {this.gameVid('yt:z1up2E93-iY,hjQrwGo_jq8,SK-BsjHJvu4,U82wN7obdcg,jOsCG4LeBYA', '2020', '2', '32', 'F', 'youtubeMulti')}
                </div>
                <div className={'ro16'}>
                  {this.gameVid('yt:zQPvWGJ1rDU,cpLS_Z3ghNY,21sC6KX1wgo,He8ER3DCBOE,lIjn4Pd-dZ0', '2020', '2', '16', 'A', 'youtubeMulti')}
                  {this.gameVid('yt:o9X_IIFJ9xU,T45bme9asMI,kUaLWpPTOO4,oX-PATjKqY0,jcjJhQAT5ZE', '2020', '2', '16', 'B', 'youtubeMulti')}
                  {this.gameVid('yt:fdza0DOjjsY,EsUkGMVDTSw,73fsg8q1M9c,yjjqeG3Ks7U,OfxSiI8ZM8M', '2020', '2', '16', 'C', 'youtubeMulti')}
                  {this.gameVid('yt:Wfb5ZmBHia4,EfTGNJL2VlU,Aw_EZ6wHwrE,MObI1EFRiT4,-DBwR3oJadg', '2020', '2', '16', 'D', 'youtubeMulti')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('yt:USG8_zYPq1U,fEJjWXK6fL8', '2020', '2', '8', '1', 'youtubeMulti')}
                  {this.gameVid('yt:0wmNE7nqR_c,EvCkCxpz3iU', '2020', '2', '8', '2', 'youtubeMulti')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('kQEJJlXlD74', '2020', '2', '4', '1', 'youtube')}
                  {this.gameVid('sEYEd4EZwc4', '2020', '2', '4', '2', 'youtube')}
                </div>
                <div className={'ro2'}>{this.gameVid('grTt3OdrvTA', '2020', '2', '2', 'F', 'youtube')}</div>
              </div>
              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>st1</div>
                <div className={'ro16'}>
                  {this.gameVid('yt:Dg5dYN2A44c,Oc7u5HMS3eI,ri6XmnFfmB4,cGma3yiTimo,HqWobbOx03w,qraazX6FeJ4,7-CzSQSUHCs,u2KIJiaoWWI', '2020', 'st1', '16', '16', 'youtubeMulti')}
                  {this.gameVid('yt:CCnBGjkZdo4,fMRt9dhAP4s,cUoxhXYAo10,PRUJTe60ahk', '2020', 'st1', '8', '8', 'youtubeMulti')}
                  {this.gameVid('yt:4EuXDQDo3T8,39NXUuC2nts', '2020', 'st1', '4', '4', 'youtubeMulti')}
                  {this.gameVid('HBINsLYKkp4', '2020', 'st1', '2', 'F', 'youtube')}
                </div>
                <div className={'seasonTitle'}>st2</div>
                <div className={'ro16'}>
                  {this.gameVid('yt:3GHxp7jUYgI,zaboP1mREVo,vX-2HVBni78,hdvXBpqtQl4,58tgH0tvUN8,NKYKWKuJarc,4YoRjsLkino,iNa2O8awIKc', '2020', 'st2', '16', '16', 'youtubeMulti')}
                  {this.gameVid('yt:xJfPoUFMN3Y,riY4Jjm95hc,dEREmMsrRYg,yBIpVRO_AYA', '2020', 'st2', '8', '8', 'youtubeMulti')}
                  {this.gameVid('yt:auyKrgEHcS4,MyoMY6FOmVc', '2020', 'st2', '4', '4', 'youtubeMulti')}
                  {this.gameVid('yt:opopXKShk4Y,Cy0WjS_h_WU', '2020', 'st2', '2', 'F', 'youtubeMulti')}
                </div>
              </div>
            </div>

            <div className={'yearRow'}>
              <div className={'yearTitle'}>2021</div>
              <div className={'roundTitles'}>
                <div className={'titleSeason'}></div>
                <div className={'title32'}>32</div>
                <div className={'title16'}>16</div>
                <div className={'title8'}>8</div>
                <div className={'title4'}>4</div>
                <div className={'titleF'}>F</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s1</div>
                <div className={`ro32 ${emptyRoundCss}`} />
                <div className={'ro16'}>
                  {this.gameVid('yt:90gGTwW1DA0,BDruHDQsFLI,6tZv8CGTftw,z0XDfOgss-w,OP4z6EFA7y0', '2021', '1', '16', 'A', 'youtubeMulti')}
                  {this.gameVid('yt:gQarc1dcpvw,XXmMbImitQM,qAfSJ8w-Js0,F7b3JQS8Gws,EuLug3IsyKI', '2021', '1', '16', 'B', 'youtubeMulti')}
                  {this.gameVid('yt:TRLKRYSBulA,5avmsoVk6zI,w_nbNaFmITk,q-nBjhvfqPs,ZpBEQffRlto', '2021', '1', '16', 'C', 'youtubeMulti')}
                  {this.gameVid('yt:M2xlLMdWXqQ,IqbRHIu18Qg,SKX0aNNHDkQ,LBXpzhkpet4,qVjv6-pyJww', '2021', '1', '16', 'D', 'youtubeMulti')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('yt:O0kPv-TP_p4,Ox1BBf-VH8s', '2020', '1', '8', '1', 'youtubeMulti')}
                  {this.gameVid('yt:lEsOhSCNVy4,hXkzTOVOwgs', '2020', '1', '8', '2', 'youtubeMulti')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('nSGgjrrzVCc', '2021', '1', '4', '1', 'youtube')}
                  {this.gameVid('6wVZK0W0SAo', '2021', '1', '4', '2', 'youtube')}
                </div>
                <div className={'ro2'}>{this.gameVid('BS0v_yEASb4', '2021', '1', '2', 'F', 'youtube')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s2</div>
                <div className={`ro32 ${emptyRoundCss}`} />
                <div className={'ro16'}>
                  {this.gameVid('yt:LkByDAg5zh4,r4LxgpfIBwo,OBZqPaGDb_Q,uRCoupvObMI,Ejcdbh-f2T4', '2021', '2', '16', 'B', 'youtubeMulti')}
                  {this.gameVid('yt:K3gX29nibTQ,LiTA6Y9CvqA,c6xOFOcOI2g,gYD0Up51lfk,qOOUIZIIig0', '2021', '2', '16', 'A', 'youtubeMulti')}
                  {this.gameVid('yt:gpOSAiacEEU,CjE9l8Yo43E,8hp0qrnUVks,whfT5WRtZjk,Hp8lFy_EAJA', '2021', '2', '16', 'C', 'youtubeMulti')}
                  {this.gameVid('yt:p99UZjvcgec,-Ry7SVwnsEA,BgN8zd3xQ2g,gQipl-4D6SI,38KUW4KHlG0', '2021', '2', '16', 'D', 'youtubeMulti')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('yt:PMCdYYuU9I4,Xy2S0QNXBkQ', '2020', '2', '8', '1', 'youtubeMulti')}
                  {this.gameVid('yt:cSPXyujlXY4,UPkDvjcr6-A', '2020', '2', '8', '2', 'youtubeMulti')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('a3hEx_1cgLE', '2021', '2', '4', '1', 'youtube')}
                  {this.gameVid('hVaniNQb63Q', '2021', '2', '4', '2', 'youtube')}
                </div>
                <div className={'ro2'}>{this.gameVid('Tcf8-X0fpoo', '2021', '2', '2', 'F', 'youtube')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>s3</div>
                <div className={`ro32 ${emptyRoundCss}`} />
                <div className={'ro16'}>
                  {this.gameVid('yt:gqA3FNvLzrQ,dLnnqPkIqrM,X2U14LD69Gw,Y_PeuTE2qpE,mmKhxyfXRCU', '2021', '3', '16', 'A', 'youtubeMulti')}
                  {this.gameVid('yt:aVoqyR9pS7Y,LRGfBSexrnI,N4IrSGFoQ8s,DIyylw2G9us,Mewlm096ApU', '2021', '3', '16', 'B', 'youtubeMulti')}
                  {this.gameVid('yt:Hoyv-GUbn-4,MjeEVbBzDiY,_hmsuiNVIOI,SctC31uazZ0,6ZjUlu8cYl8', '2021', '3', '16', 'D', 'youtubeMulti')}
                  {this.gameVid('yt:UpHpyo90EiU,jT3unB0GBNk,sdtGBzjaaBU,K7bnIfm1V9U,b1JaqMUX5WI', '2021', '3', '16', 'C', 'youtubeMulti')}
                </div>
                <div className={'ro8'}>
                  {this.gameVid('yt:dYI4PxSSlNY,lWO6z3_v1iM', '2020', '3', '8', '1', 'youtubeMulti')}
                  {this.gameVid('yt:tbFQa1NOk38,ay7RkBT-Ihs', '2020', '3', '8', '2', 'youtubeMulti')}
                </div>
                <div className={'ro4'}>
                  {this.gameVid('k-S4ZRlMf6Q', '2021', '3', '4', '1', 'youtube')}
                  {this.gameVid('WcfjLApUMZI', '2021', '3', '4', '2', 'youtube')}
                </div>
                <div className={'ro2'}>{this.gameVid('NlVbLCY7jQk', '2021', '3', '2', 'F', 'youtube')}</div>
              </div>

              <div className={'seasonCol'}>
                <div className={'seasonTitle'}>st1</div>
                <div className={'ro16'}>
                  {this.gameVid('yt:T5r3lpMmBVQ,LQ7kB7epGh0,cCt-nIinDfU,ds39jjYZ17E,OwLzMRB4CZ0,qhf_UWUyc0Y,K7wcaB8NP5M,BIXrnsqMlYo', '2021', 'st1', '16', '16', 'youtubeMulti')}
                  {this.gameVid('yt:QLAWXOiuTZQ,VVTAy7joFJ0,TfhBCxRdJhY,aBC_9kCXlYw', '2021', 'st1', '8', '8', 'youtubeMulti')}
                  {this.gameVid('yt:U-bjwfQ4eME,IHQhZETKNE4', '2021', 'st1', '4', '4', 'youtubeMulti')}
                  {this.gameVid('mIngPwt4SWk', '2021', 'st1', '2', 'F', 'youtube')}
                </div>
                <div className={'seasonTitle'}>st2</div>
                <div className={'ro16'}>
                  {this.gameVid('yt:IFL767DZ2J0,rcUEPS_jhuY,nUGEHy_u6tA,bPDrecN7C0U,Ch7VNbB4HEA,FAAJKM74JL0,QI_HP__nnbg,xEC3DkMX96o', '2021', 'st2', '16', '16', 'youtubeMulti')}
                  {this.gameVid('yt:O0YX_lJqWeY,mFXf7xzCIA4,p85tCcqXbI8,lCLxnBavPXU', '2021', 'st2', '8', '8', 'youtubeMulti')}
                  {this.gameVid('yt:r8QNCI_S-PI,9Zgrg2I2_3g', '2021', 'st2', '4', '4', 'youtubeMulti')}
                  {this.gameVid('n1-iijcLQvs', '2021', 'st2', '2', 'F', 'youtube')}
                </div>
              </div>
            </div>

            <div className={'hidden'}>
              <div>Season3</div>
              <a className={setLinkCss} href="" target="_blank">
                A
              </a>
              <a className={setLinkCss} href="" target="_blank">
                B
              </a>
              <a className={setLinkCss} href="" target="_blank">
                C
              </a>
              <a className={setLinkCss} href="" target="_blank">
                D
              </a>
              <a className={setLinkCss} href="" target="_blank">
                E
              </a>
              <a className={setLinkCss} href="" target="_blank">
                F
              </a>
              <a className={setLinkCss} href="" target="_blank">
                G
              </a>
              <a className={setLinkCss} href="" target="_blank">
                H
              </a>
              <a className={setLinkCss} href="" target="_blank">
                A
              </a>
              <a className={setLinkCss} href="" target="_blank">
                B
              </a>
              <a className={setLinkCss} href="" target="_blank">
                C
              </a>
              <a className={setLinkCss} href="" target="_blank">
                D
              </a>
              <a className={setLinkCss} href="" target="_blank">
                1
              </a>
              <a className={setLinkCss} href="" target="_blank">
                2
              </a>
              <a className={setLinkCss} href="" target="_blank">
                1
              </a>
              <a className={setLinkCss} href="" target="_blank">
                2
              </a>
              <a className={setLinkCss} href="" target="_blank">
                F
              </a>

              <div>Season3</div>
              <a className={setLinkCss} href="" target="_blank">
                A
              </a>
              <a className={setLinkCss} href="" target="_blank">
                B
              </a>
              <a className={setLinkCss} href="" target="_blank">
                C
              </a>
              <a className={setLinkCss} href="" target="_blank">
                D
              </a>
              <a className={setLinkCss} href="" target="_blank">
                E
              </a>
              <a className={setLinkCss} href="" target="_blank">
                F
              </a>
              <a className={setLinkCss} href="" target="_blank">
                G
              </a>
              <a className={setLinkCss} href="" target="_blank">
                H
              </a>
              <a className={setLinkCss} href="" target="_blank">
                A
              </a>
              <a className={setLinkCss} href="" target="_blank">
                B
              </a>
              <a className={setLinkCss} href="" target="_blank">
                C
              </a>
              <a className={setLinkCss} href="" target="_blank">
                D
              </a>
              <a className={setLinkCss} href="" target="_blank">
                1
              </a>
              <a className={setLinkCss} href="" target="_blank">
                2
              </a>
              <a className={setLinkCss} href="" target="_blank">
                1
              </a>
              <a className={setLinkCss} href="" target="_blank">
                2
              </a>
              <a className={setLinkCss} href="" target="_blank">
                F
              </a>
            </div>
          </div>
        </div>

        <div className={'footer'}></div>
      </div>
    )
  }
}

export default withCookies(New)
