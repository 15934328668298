/* eslint-env browser */
import React from 'react'
import ReactDOM from 'react-dom'

import {Provider} from 'react-redux'
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'

//import { CookiesProvider } from 'react-cookie';

//import Counter from './Counter';
//import Home from './Home';
//import UploadImg from './UploadImg';

//import store from './redux/store';
import './styles.css'

import {CookiesProvider} from 'react-cookie'
import Home from './Home'
import Look from './Look'
import Ai from './Ai'
import New from './New'
import Moba from './Moba'

const App = () => (
  <CookiesProvider>
    <Router>
      <Switch>
        <Route path="/look">
          <Look />
        </Route>
        <Route path="/ai">
          <Ai />
        </Route>
        <Route path="/new">
          <New />
        </Route>
        <Route path="/moba">
          <Moba />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  </CookiesProvider>
)

/*
<Route path="/uploadImg">
  <UploadImg />
</Route>
*/

ReactDOM.render(<App />, document.getElementById('app'))

module.hot.accept()
