import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

let Look = ({}) => {
  const [question, setQuestion] = useState('')
  const [response, setResponse] = useState('')

  const handleSubmit = async () => {
    try {
      const res = await axios.post('http://localhost:5000/ask', {question})
      setResponse(res.data.answer)
    } catch (error) {
      console.error('Error asking the question:', error)
    }
  }

  return (
    <div style={local.page}>
      <div style={local.plus}>{`+`}</div>
      <div style={local.chart} />
      <div className="App">
        <h1>Ask OpenAI</h1>
        <textarea rows="10" cols="50" value={question} onChange={e => setQuestion(e.target.value)} placeholder="Enter your question here..." />
        <br />
        <button onClick={handleSubmit}>Ask</button>
        <h2>Response</h2>
        <pre>{response}</pre>
      </div>
    </div>
  )
}

let local = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 330,
    alignSelf: 'center',
    marginBottom: 80,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    alignSelf: 'stretch',
    marginBottom: 5,
    marginTop: 111,
  },
  plus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 42,
    height: 42,
    marginRight: 4,
    border: '1px solid #DBD3D3',
    borderRadius: 6,
    margin: 9,
    color: '#ccc',
    fontSize: 18,
  },
  chart: {
    alignSelf: 'stretch',
    flex: 1,
    height: 58,
    marginLeft: 2,
    borderRadius: 6,
    border: '1px solid #DBD3D3',
  },
}

export default Look
