import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import axios from 'axios'

//import Ex1 from './moba/Ex1'
import Ex2 from './moba/Ex2'

let Look = ({}) => {
  const [question, setQuestion] = useState('')

  let onClick = () => {
    console.log('onClikc')
  }

  return (
    <>
      <Ex2 />
    </>
  )
}

let local = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 330,
    alignSelf: 'center',
    marginBottom: 80,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    alignSelf: 'stretch',
    marginBottom: 5,
    marginTop: 111,
  },
  plus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 42,
    height: 42,
    marginRight: 4,
    border: '1px solid #DBD3D3',
    borderRadius: 6,
    margin: 9,
    color: '#ccc',
    fontSize: 18,
  },
  chart: {
    alignSelf: 'stretch',
    flex: 1,
    height: 58,
    marginLeft: 2,
    borderRadius: 6,
    border: '1px solid #DBD3D3',
  },
}

export default Look
