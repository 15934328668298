import React from 'react'
import all from '../tasty/all'

const Look = () => {
  return (
    <div style={local.pageContainer}>
      <div style={local.page}>
        {all.map((video, index) => (
          <div key={index} style={local.row}>
            <div style={{minWidth: 150, backgroundColor: 'pink'}}>{video.videoId}</div>
            <div style={{width: 700, backgroundColor: '#cedde2'}}>{video.title}</div>
            <div>{video.description}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const local = {
  pageContainer: {
    display: 'flex',
    overflowX: 'auto',
    width: '100vw',
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
    border: '1px solid #ddd',
    borderRadius: 5,
    whiteSpace: 'nowrap',
  },
}

export default Look
